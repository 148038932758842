*, *:after, *:before {
  box-sizing: border-box;
}

.font-face-farsi {
  font-family: "IranNastaliq";
}

.feedBackSubjectBody {
  margin: 30px auto;
}

.feedBackSubjectContainer {
  display: flex;
  justify-content: space-around;
}

.feedBackSubjectContainer div {
  display: flex;
  margin: 20px 0;
  width: 35%;
}

.feedBackSubjectContainer div h4 {
  font-size: 20px;
  font-weight: 900;
  margin: 0;
}

.feedBackSubjectContainer div span {
  font-size: 17px;
  font-weight: 700;
  color: #73726F;
  margin: auto auto auto 30px;
}


.feedBackTextContainer {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.feedBackTextContainer div {
  margin: 10px 15px 20px 18px;
  width: 15%;
  text-align: center;
}

.feedBackTextContainer div h4 {
  font-size: 20px;
  font-weight: 900;
}

.feedBackSubjectText {
  width: 85%;
  margin: 10px auto 20px auto;
  font-weight: 500;
  font-size: 16px;
  color: #73726F;
  min-height: 60px;
}

.feedBackInputsContainer {
  display: flex;
}

.feedBackInputsContainer .inputGP {
  width: 100%;
  margin: 0 15px;
  border-left: 3px solid;
}

.feedBackInputsContainer .inputGPBNN {
  width: 100%;
  margin: 0 15px;
  border-right: 3px solid;
}

.feedBackTextAreaContainer {
  width: 100%;
  display: flex;
}

.feedBackTextAreaContainer .textareaGP {
  width: 100%;
  display: flex;
  margin: 0 15px;
  border-left: 3px solid;
}

.feedBackTextAreaContainer .textareaGPBNN {
  width: 100%;
  display: flex;
  margin: 0 15px;
  border-right: 3px solid;
}


.feedBackTextAreaContainer .textareaGP textarea, .feedBackTextAreaContainer .textareaGPBNN textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 98%;
  outline: 0;
  border: 0;
  padding: 7px;
  background: transparent;
  transition: border-color 0.2s;
  margin: 40px 15px 20px 30px;
  border-radius: 15px;
}

$primary: #fff;
$secondary: #fff;
$white: #fff;
$gray: #9b9b9b;
.form__group {
  position: relative;
  padding: 0 0 10px 0;
  margin-top: 20px;
  border-top: none;
  border-right: none;
  border-left: none;
}

.form__labelFeedBack {
  margin: auto 0 0 0;
  height: fit-content;
  font-size: 1.1rem;
  cursor: text;
  min-width: 70px;
  text-align: center;
}

.feedBackTextAreaContainer .form__labelFeedBack{
  margin: 30px 0 0 0;
}

.form__field {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 7px;
  margin: 0 7px;
  background: transparent;
  transition: border-color 0.2s;
  border-bottom: dashed 2px;

  &::placeholder {
    color: transparent;
  }
}

.FeedBackBtnContainer {
  display: flex;
  justify-content: center;
}

.FeedBackBtn {
  margin: 20px auto 5px auto;
  width: 100px;
  border: none;
  background-color: transparent;
  font-family: HelveticaNeueCyrBold;
}


.pagesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 4rem 0;
  overflow: hidden;
}


.items-list {
  max-width: 90vw;
  margin: 2rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3rem;
  justify-content: center;
  align-content: center;

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.item {
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    span {
      transform: scale(1.2);
      color: var(--primary);
    }
  }

  p {
    font-size: 1.2rem;
    margin-top: 1rem;
    color: var(--greyLight);
  }
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;

  border-radius: 0.6rem;
  box-shadow: 0 0.8rem 2rem rgba(#5a6181, 0.05);

  &__numbers,
  &__btn,
  &__dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.8rem;
    font-size: 1.4rem;
    cursor: pointer;
  }

  &__dots {
    width: 2.6rem;
    height: 2.6rem;
    color: #727272;
    cursor: initial;
  }

  &__numbers {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 0.4rem;

    &:hover {
      color: var(--primary);
    }

    &.active {
      color: #ffffff;
      background: #6a1a21;
      font-weight: 600;
      border: 1px solid var(--primary);
      border-radius: 50%;
    }
  }

  &__btn {
    color: var(--greyLight);
    pointer-events: none;

    &.active {
      color: var(--greyDark);
      pointer-events: initial;

      &:hover {
        color: var(--primary);
      }
    }
  }
}

.disabledInput {
  pointer-events: none;
  opacity: 0.5;
}

@media screen and (max-width: 900px) {

  .feedBackSubjectContainer div {
    display: block;
  }

}