body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, IranNastaliq, Helvetica-Black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.curvy {
    border-radius: 30px;
}

.BGDark {
    background-image: url("../src/component/css/headers/aboutUs2.svg"), linear-gradient(45deg, #161E31, #181C27, #181C27, #161E31);
    background-repeat: no-repeat;
    background-size: cover;
    font-family: Helvetica-Black;
    min-height: 937px;
}

.BGLight {
    background: #FFFFFF;
    background: linear-gradient(130deg, rgb(221, 224, 228) 0%, rgb(221, 224, 228) 30%,rgb(221, 224, 228) 70%, rgba(18,43,95,0.2) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    font-family: Helvetica-Black;
    min-height: 937px;
}


@font-face {
    font-family: IranNastaliq;
    src: local("GoldmanBold"),
    url(component/css/OtherFonts/EstedadFont/Estedad-Medium.ttf);
}

@font-face {
    font-family: Helvetica-Black;
    src: local("GoldmanBold"),
    url(component/css/OtherFonts/Helvetica_Fonts_Complete_Collection[faragraphic.com]/HelveticaFamily/HelveticaNeue-Light.otf);
}

@font-face {
    font-family: HelveticaNeueCyrBold;
    src: local("GoldmanBold"),
    url(component/css/OtherFonts/Helvetica_Fonts_Complete_Collection[faragraphic.com]/Helvetica Neue Cyr/HelveticaNeueCyr-Bold.otf);
}

@font-face {
    font-family: HelveticaNeueCyrMid;
    src: local("GoldmanBold"),
    url(component/css/OtherFonts/Helvetica_Fonts_Complete_Collection[faragraphic.com]/Helvetica Neue Cyr/HelveticaNeueCyr-Medium.otf);
}

@font-face {
    font-family: HelveticaNeueMediumCond;
    src: local("GoldmanBold"),
    url(component/css/OtherFonts/Helvetica_Fonts_Complete_Collection[faragraphic.com]/HelveticaFamily/HelveticaNeue-Heavy.otf);
}

@media screen and (min-width: 1600px) {
    .BGDark {
        min-height: 1100px;
    }

    .BGLight {
        min-height: 1100px;
    }
}


@media screen and (min-width: 1800px) {
    .BGDark {
        min-height: 1200px;
    }

    .BGLight {
        min-height: 1200px;
    }
}

@media screen and (min-width: 2000px) {
    .BGDark {
        min-height: 1440px;
    }

    .BGLight {
        min-height: 1440px;
    }
}