/*nav coding : */
/*styles & hovers*/

.font-face-farsi {
  font-family: "IranNastaliq";
}

nav {
  position: relative;
  z-index: 1000;
  background-color: transparent;
}

#navMenu .navbar-brand img {
  width: 70px;
  height: 55px;
  margin: 10px 0 0 40px;
}

#navMenu .navbar-brand {
  transition: 0.3s all ease-in-out;
}

#navMenu .navbar-brand:hover {
  filter: brightness(1.4);
}

.end-items li, .start-items li {
  margin: 5px 5px 0px 0px;
  font-size: 15px;
}

.end-items, .start-items {
  padding: 0;
}

.start-items li {
  font-size: 17px;
  padding: 0 15px;
}

.end-items li {
  padding: 10px 15px 0 15px;
  font-size: 20px;
}


.end-items li button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 20px;
  border-radius: 0.25rem;
  padding: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.LogInNav {
  width: 100px;
  display: inline-block;
  font-weight: 400;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.start-items.BNN li a {
  font-size: 19px;
  font-weight: 700;
}

#navMenu .dark-them {
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  border: none;
}

#navMenu .dark-them.Logo {
  cursor: pointer;
}

.navbar-toggler span {
  color: #FFFFFF;
  fontSize: 3rem;
}

#navMenu .light-them {
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  color: #000d1a;
  font-weight: bold;
  border: none;
}

#navMenu .light-them:hover {

  transition: 0.3s;
  text-decoration: none;
  background-color: transparent;
}

#navMenu .dark-them:hover {

  transition: 0.3s;
  text-decoration: none;
  background-color: transparent;
}

#started {
  text-decoration: none;
  border: none;
  font-size: 17px;
  width: 80px;
}

#started:hover {
  text-decoration: none;
  border: none;
}


/*a:hover, a:active, .page-link:hover, .page-link:active, #linked-nav:hover, #linked-nav:active {*/
/*    color: #ffa31a;*/
/*    transition: 0.3s;*/
/*    text-decoration: none;*/
/*    background-color: transparent;*/
/*}*/

/*#navMenu .en:hover, #navMenu .en:active, #navMenu .ico:hover, #navMenu .ico:active, #navMenu .Usd:hover, #navMenu .Usd:active, #navMenu .login:hover, #navMenu .login:active {*/
/*    color: #ffa31a;*/
/*    transition: 0.3s;*/
/*    text-decoration: none;*/
/*    background-color: transparent;*/
/*}*/

/*-------------------------------------------------------------------------------------------------------------------------------------------------------*/

/*nav icons styles*/
.usd {
  border-left: solid 1px #bfbfbf;
  border-right: solid 1px #bfbfbf;
  font-weight: bold;
}

.darkThemAll {
  font-weight: bold;
  cursor: pointer;
}

.ico {
  font-size: 18px;
}

.ico:hover i {
  transition: 0.3s;
  text-decoration: none;
  background-color: transparent;
}

/*-------------------------------------------------------------------------------------------------------------------------------------------------------*/

/*navs drowp downs !not complete!*/
.app-dropdown {
  padding: 0px 8px 0px 8px;
}

.app-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 800px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.app-dropdown:hover .app-dropdown-content {
  display: block;
}


.nav-item {
  margin-right: 3px;
}

#hidden-started {
  display: none;
  margin: 10px 15px auto auto;
}

#hidden-started:hover {
  text-decoration: none;
  border: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

//*/*/*/*/*/**/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/*/**/*/*/*/*/*/*/*/*/*/*/*/*/**/*/*/*/*//////*/*/*/*/*/*/**/*/*/*/*/*/*/

.Nav-menu {
  position: relative;
}

.Nav-menu:hover .Nav-menu-dropdown {
  visibility: visible;
  transform: translateY(0px);
}

.Nav-menu .Nav-menu-dropdown {
  color: transparent;
  visibility: hidden;
  position: absolute;
  padding: 20px 0;
  transform: translateY(50px);
  right: 0;
  top: 110%;
  min-width: 200px;
  border-radius: 8px;
  z-index: 111;
  transition: 0.3s all;
}

.Nav-menu .Nav-menu-dropdown a {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
}

.Nav-menu-dropdown li span {
  padding: 5px 0;
  font-weight: 600;
  font-family: IranNastaliq;

}

.Nav-menu-dropdown li span:hover {
  padding-left: 10px;
  filter: brightness(1.5);
  transition: all .4s ease-in-out;
}

.Nav-menu-dropdown li span {
  cursor: pointer;

}


//$menu_WIDTH: 150px;
//
//.Nav-menu {
//  display: block;
//  position: relative;
//  cursor: pointer;
//}
//
//.Nav-menu-title {
//  display: block;
//  text-align: center;
//  padding: 0;
//  font-weight: bold;
//  font-size: 20px;
//  text-transform: uppercase;
//  transition: 0.3s background-color;
//}
//
//.Nav-menu-title:focus {
//  box-shadow: none;
//}
//
//
//
//.Nav-menu-title:before {
//  content: "";
//  display: block;
//  height: 0;
//  position: absolute;
//  top: 100%;
//  left: 0;
//  z-index: 101;
//  transition: 0.2s 0.2s border-top ease-out,
//  0.3s border-top-color;
//}
//
//
//.Nav-menu:hover > .Nav-menu-title:before {
//  border-top-width: 0;
//  transition: 0.2s border-top-width ease-in,
//  0.3s border-top-color;
//}
//
//.Nav-menu-title:after {
//  content: "";
//  display: block;
//  height: 0;
//  position: absolute;
//  bottom: 0;
//  left: 0;
//  z-index: 101;
//  transition: 0.2s border-bottom ease-in;
//}
//
//.Nav-menu:hover > .Nav-menu-title:after {
//  border-bottom-width: 5px;
//  transition: 0.2s 0.2s border-bottom-width ease-out;
//}
//
//.walletIco {
//  margin-right: 5px;
//}

//.Nav-menu-dropdown {
//  min-width: 100%;
//  padding: 5px 15px;
//  margin-top: 10px;
//  position: absolute;
//  background: #ebebeb;
//  z-index: 100;
//  left: -50px;
//  transition: 0.5s padding,
//  0.5s background;
//  border-radius: 8px;
//}
//
//.language-dropdown {
//  width: 140px;
//}
//
//.Nav-menu-dropdown > ul:before, .Nav-menu-dropdown > ul:after {
//  content: "";
//  position: absolute;
//  z-index: -1;
//  box-shadow: 0 2px 3px #1a8cff;
//  top: 50%;
//  bottom: 0px;
//  left: 5px;
//  right: 5px;
//  border-radius: 100px / 10px;
//}
//
//
//.Nav-menu-dropdown .MuiListItemText-root {
//  color: #1a8cff;
//  font-size: 17px;
//  font-weight: 500;
//
//}
//
//.walletDropdown .MuiListItemText-root, .account-dropdown .MuiListItemText-root , .language-dropdown .MuiListItemText-root {
//  padding: 10px 0;
//}
//
//.Nav-menu-dropdown:after {
//  content: "";
//  display: block;
//  height: 0;
//  position: absolute;
//  top: 100%;
//  left: 0;
//  z-index: 101;
//  transition: 0.5s border-top;
//}
//
//.Nav-menu:not(:hover) > .Nav-menu-dropdown {
//  padding: 0 0;
//  background: #dddddd;
//  z-index: 99;
//}
//
//.Nav-menu:not(:hover) > .Nav-menu-dropdown:after {
//  border-top-color: #dddddd;
//}
//
//.Nav-menu:not(:hover) > .Nav-menu-title:after {
//  border-bottom-color: #dddddd;
//}
//
//.Nav-menu-dropdown > * {
//  overflow: hidden;
//  padding: 5px 10px;
//  background: rgba(0, 0, 0, 0);
//  white-space: nowrap;
//  transition: 0.4s height cubic-bezier(.73, .32, .34, 1.5),
//  0.4s padding cubic-bezier(.73, .32, .34, 1.5),
//  0.4s margin cubic-bezier(.73, .32, .34, 1.5),
//  0.4s 0.2s color,
//  0.2s background-color;
//}
//
//.Nav-menu-dropdown > *:hover {
//  background: rgba(0, 0, 0, 0.1);
//}
//
//.Nav-menu:not(:hover) > .Nav-menu-dropdown > * {
//  visibility: hidden;
//  height: 0;
//  padding-top: 0;
//  padding-bottom: 0;
//  margin: 0;
//  color: rgba(25, 25, 25, 0);
//  transition: 0.1s 0.1s height,
//  0.1s 0.1s padding,
//  0.1s 0.1s margin,
//  0.2s color,
//  0.1s visibility;
//  z-index: 99;
//}

.hList {
  //overflow: hidden;
}

.hList > * {
  float: left;
}

.hList > * + * {
  margin-left: 0;
}

.Nav-menu-dropdown li {
  font-size: 15px;
}

.checked {
  color: #26a69a;
  float: right;
  padding-right: 20px;
  display: inline-flex;
}

.languageIco-hidden, .DMLAN-hidden {
  display: none;
}


.languageIco {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DMLAN-hidden {
  font-size: 22px;
}

.languageIco-hidden {
  font-size: 22px;
  line-height: 2.1;
}

.secLan {
  margin-right: 5px;
}

.LightModIco, .DarkModeIco, .SettingIco, .AccInfo, .OrdersIco, .WalletIco {
  width: 25px;
  height: 25px;
}


@media screen and (max-width: 990px) {

  .walletDropdown {
    left: 0;
  }

  .navbar-collapse {
    transition: all .5s ease-in-out;
    transform-origin: left top;
    transform: scaleY(0);
  }

  .NavColl {
    transform: scaleY(1);
    display: block !important;
  }

  #navMenu .navbar-brand img {
    padding: 6px 0 0 0;
    margin: 0;
    width: 60px;
    height: 45px;
  }

  .languageIco-hidden, .DMLAN-hidden {
    display: block;
  }

  .darkThemAll, .languageChanger {
    display: none;
    vertical-align: bottom;
  }


  #started, .NavLoginBtn {
    display: none;
  }

  #hidden-started {
    display: block;
  }

  #hidden-started .LogInNav {
    display: block;
    width: 70px;

  }

  #hidden-started .LogInNav svg {
    width: 70px;
    height: 33px;
  }

  .header-title {
    padding-top: 60px;
  }


  .navbar-text li {
    width: fit-content;
  }

  .navbar-nav .usd {
    border: none;
  }

  .start-items, .end-items {
    justify-content: space-around;
  }

}

@media screen and (max-width: 400px) {

  #started {
    display: none;
  }

  #hidden-started {
    display: block;
  }

}

@media screen and (max-width: 350px) {

  #navMenu .navbar-brand {
    font-size: 20px;
  }

  ol, ul {
    padding: 0;
  }

}