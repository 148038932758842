
body {
    overflow-x: hidden;
}


.font-face-farsi {
    font-family: "IranNastaliq";

}

.container-fluid {
    padding: 0;
}

.magic {
    display: none;
    margin-left: 10px;
}

/*logo in the flexible header*/
.header-branding {
    width: 100%;

}

/*-------------------------------------------------------------------------------------------------------------------------------------------------------*/
/*header , pricecs & staff*/

.center ul {
    margin: auto 50px;
}

.center {
    margin: auto;
    color: #bbbbbb;
}

.header {
    position: relative;
}

.header.darkMode {
    min-height: 970px;
    background-position: 90% -15%;
    background-image: url("headers/banner.svg");
    background-size: 50% 70%;
    background-repeat: no-repeat;
    margin: 0 0 30px 0;
}

.header.lightMode {
    min-height: 970px;
    background-position: 90% -15%;
    background-image: url("headers/bannerLight.svg");
    background-size: 50% 70%;
    background-repeat: no-repeat;
}


.header .header-title {
    margin: 0px 0;
    text-align: center;
    width: 45%;
    text-transform: uppercase;
    position: absolute;
    bottom: 50%;
}

.header .header-title h2 {
    font-size: 50px;
    margin: 10px 40px 10px 40px;
}

.header .JoinNow {
    width: 180px;
    margin: 10px auto;
}

.header .header-title .card-title.BNN {
    font-size: 37px;
    font-weight: 700;
}

.header .card {
    margin-right: 10px;
    background-color: #2e2d2f;
    color: #f2f2f2;
}


.header .card-title {
    margin: 0px;
    padding-top: 130px;
}

.header .card .card-body {
    padding: 0px;

}

.header.darkMode .sliderPercent {
    font-size: 20px;
    margin: 15px 0 0 10px;
    text-align: left;
    color: #e0e0e0;
}

.header.lightMode .sliderPercent {
    text-align: initial;
    margin: 15px 0 0 10px;
    font-size: 18px;
    color: #0d0d0d;
    font-weight: 600;
}

.header.darkMode .sliderPercent.SNN {
    margin: 15px 0 0 0;
}

.header.lightMode .sliderPercent.SNN {
    margin: 15px 0 0 0;
}

.sliderPercent span, .sliderPercent section {
    margin-top: 5px;
}

.header.darkMode .sliderPercent section, .crypto-property-postFix {
    font-size: 12px;
    color: #a9a9a9;
    font-weight: 700;
}

.header.lightMode .sliderPercent section, .crypto-property-postFix {
    font-size: 12px;
    color: #474747;
    font-weight: 700;
}

.header .exact-price {
    font-size: 20px;
    text-align: left;
    padding: 0 16px;
    margin: 10px 0 0 0;
}

.priceList {
    box-shadow: 4px 7px .5rem 3px rgba(10, 10, 10, 0.6);
    width: 20%;
    margin: 10px;
    border: transparent;
    max-width: 330px;
    border-radius: 30px;
    padding: 17px;
}

.header .price {
    color: #995c00;
    font-weight: bold;
}

.inGrow {
    color: #0f7269;
}

.inLose {
    color: #a61e1b;
}

.header #join {
    background-color: #003366;
    border: 1px solid transparent;
    font-weight: bold;
    padding: 10px 15px;
    margin: 30px 0 0 0;
    color: #e6e6e6;

}

.header.faMode #join {
    background-color: #003366;
    border: 1px solid transparent;
    font-weight: bold;
    padding: 10px 15px;
    margin: 30px 0 0 0;
    color: #e6e6e6;
}

.header #join:hover {
    border: 1px solid transparent;
    background-color: #6e8791;
    transition: 0.5s;
    color: #1a1a1a;
}

#sentence1 ul {
    justify-content: center;

}

#sentence1 li {
    margin: 0px 10px 0px 10px;
    border: none;
    background-color: #2e2d2f;
    color: #f2f2f2;

}

#sentence1, #sentence2, #sentence3, #sentence4, #sentence5, #sentence6 {
    overflow: hidden;
    border: none;
    width: 100%;
    /*font-size: 42px;*/
    /*font-weight: bold;*/
}

/*#sentence > li, #sentence > ul {*/
/*    display: inline;*/
/*}*/


#textSlider1, #textSlider2, #textSlider3, #textSlider4, #textSlider5, #textSlider6 {
    overflow: visible !important;
    /*text-align: center;*/
    /*display: inline;*/
    /*padding: 0px;*/
    /*margin-right: 10px;*/
    /*margin-left: 15px;*/
    position: relative;
    height: 16px;
    padding: 0px;

}

.adj, .adj2, .adj3, .adj4, .adj5, .adj6 {
    white-space: nowrap;
    list-style: none;
    -webkit-transform: translateY(60px);
    -ms-transform: translateY(60px);
    transform: translateY(60px);
}

.slide-in, .slide-in2, .slide-in3, .slide-in4, .slide-in5, .slide-in6 {

    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: 1.5s;
    transition: 1.5s;
}

.slide-out, .slide-out2, .slide-out3, .slide-out4, .slide-out5, .slide-out6 {

    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-transition: 1s;
    transition: 1s;
    opacity: 0;
}

.moving-text {
    box-sizing: content-box;
}

/*----------------------------------------------------------------------------------------------------------------------------------------*/

/*-------------------------------------------------------------------------------------------------------------------------------------------------------*/

/*container first section -> billboard*/

.billboard {
    margin: 50px 0px 50px 0px;
}

.arrow-tabs {
    width: 100%;
    min-width: 360px;
    padding: 20px 50px 0 50px;
}


.arrow-tabs > ul {
    text-align: center;
    font-weight: 500;
    padding: 0;
    position: relative;
    z-index: 1;
}

.arrow-tabs > ul > li {
    display: inline-block;
    padding: 0;
    position: relative;
    width: 25%;
    margin: 0 0 0 -4px;
}

.arrow-tabs > ul > li > a {
    width: 100%;
    display: block;
    padding: 0.6em 0;
}

.arrow-tabs > ul > li:before, .arrow-tabs > ul > li:after {
    opacity: 0;
    transition: 0.3s ease;
}

.arrow-tabs > ul > li.ui-tabs-active:before, .arrow-tabs > ul > li.ui-tabs-active:after {
    opacity: 1;
}

.arrow-tabs > ul > li.ui-tabs-active a {
    color: #6e8791;
}

.arrow-tabs > ul > li:hover:before, .arrow-tabs > ul > li:hover:after, .arrow-tabs > ul > li:focus:before, .arrow-tabs > ul > li:focus:after {
    opacity: 1;
}

.arrow-tabs > ul > li:before, .arrow-tabs > ul > li.ui-state-active:hover:before, .arrow-tabs > ul > li.ui-state-active:focus:before {
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 2px 3px #647984;
    top: 50%;
    bottom: 0px;
    left: 5px;
    right: 5px;
    border-radius: 100px / 10px;
}

.arrow-tabs > ul > li:after, .arrow-tabs > ul > li.ui-state-active:hover:after, .arrow-tabs > ul > li.ui-state-active:focus:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    bottom: -25px;
    margin-left: -6px;
    transform: rotate(225deg);
    box-shadow: 3px 3px 3px #647984, 1px 1px 1px rgba(0, 0, 0, 0.3);
}


.arrow-tabs > ul > li:focus {
    outline: none;
}

.arrow-tabs > ul > li a {
    text-decoration: none;
}

.arrow-tabs > ul > li a:focus {
    outline: none;
    text-decoration: none;
}

.arrow-tabs > ul > li a span {
    position: relative;
}


.crypto-list1 {
    display: none;
}

#tileRapTable {
    margin: auto 10px;
}

.HPMainTableContainer {
    display: flex;
    justify-content: center;
    margin: 30px 0 50px 0;
}

.crypto-list1, .crypto-list2, .crypto-list3, .crypto-list4 {
    width: available;
    list-style-type: none;
    height: fit-content;
    border: 1px solid transparent;
    margin: 5px;
}

.crypto-list2:hover, .crypto-list3:hover {
    /*box-shadow: 0 0 3.5rem rgba(10, 10, 10, 0.5) !important;*/
}

.crypto-list1 li, .crypto-list2 li, .crypto-list3 li, .crypto-list4 li {
    border: 2px solid transparent;
    text-align: center;
    padding: 20px 10px 10px 0;
    margin: 1px 0px 0px 0px;
    height: 60px;
}

.border-hover {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    transform: scale(0.7);
    transition: 0.5s ease-in-out;
}

.border-hover h4 {
    dominant-baseline: middle;
    text-anchor: middle;
    font-size: 17px;
    text-align: center;
}

.border-hover h4 .taleTitleTextContainer {
    dominant-baseline: middle;
    text-anchor: middle;
    font-size: 17px;
    position: relative;
}


.border-hover li p {
    margin: 0;
    min-width: 100px;
}

.border-hover:hover {
    box-shadow: -8px 0 10px rgba(85, 85, 85, 0.25);
}

.table th, td {
    text-align: center;
    padding: 8px;
    font-size: 14px;
    margin: 0;
}


.crypto-table-homepage {
    width: 100%;
    margin: 2% 0;
    height: 100%;
    padding: 0 15px;
    border-radius: 30px;
}


.tileRap {
    width: 70%;
    display: inline-block;
    position: relative;
    vertical-align: top;
    font-family: Helvetica-Black;
    outline: none;
    -webkit-transform: translate(0);
    transform: translate(0);
    -webkit-transition: box-shadow 0.25s;
    transition: box-shadow 0.25s;
    margin: 0 50px 0 0;
}


.tileRap:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 30px;
    right: -25px;
    bottom: 70px;
    z-index: -1;
    border-radius: 30px;
    transform: skew(-5deg, -2deg);
}

.tileRap:after {
    content: "";
    position: absolute;
    border-radius: 30px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.taleTitle {
    margin: auto;
    font-size: 20px;
    width: 90%;
}

.HPMainTable {
    display: flex;
    justify-content: center;
    width: fit-content;
}

.LiMain-container {
    position: absolute;
    top: -60px;
    right: -70px;
    font-family: HelveticaNeueCyrBold;
}

.glow {
    transition: box-shadow .3s;
    line-height: 44px;
}


.arrow-right {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #d9d9d9;
}

.arrow-left {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #d9d9d9;
}

.crypto-table-homepage th {
    border: none;
    text-align: center;
}


.Lose {
    color: #ef5350;
}

.crypto-ico {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;
    margin-left: 15px;
    object-fit: contain;
}

.homepage-crypto-property {
    color: #7f7f7f;
    border: none;
}


#second-table, #third-table, #forth-table {
    display: none;
}

.crypto-list2 .arrow-right, .crypto-list3 .arrow-right, .crypto-list4 .arrow-right {
    display: none;
}


.mainTableFeedback {
    width: 100%;
    position: absolute;
    top: 0;
    filter: blur(0px);
}


.blurForLoading {

    pointer-events: none;
    box-shadow: none;
}

#SCAT {
    display: none;
}

#SCAT .ui-corner-all {
    padding-bottom: 0;
    margin: 0;
}

.price-change {
    margin: 0 5px;
}

/**********************************************************************************************************************************************/

.textContainer p {
    position: relative;
    min-height: 50px;
    margin: 10px 20px 0 20px;
}

.textContainer p span.first {
    background-image: url("./cardBackgrounds/num1-01.svg");
    min-width: 40px;
    min-height: 40px;
    position: absolute;
    top: -10px;
}

.textContainer p span.second {
    background-image: url("./cardBackgrounds/num2-01.svg");
    min-width: 40px;
    min-height: 40px;
    position: absolute;
    top: -10px;
}

.textContainer p span.third {
    background-image: url("./cardBackgrounds/num3-01.svg");
    min-width: 40px;
    min-height: 40px;
    position: absolute;
    top: -10px;
}

#SCAT a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    margin: 20px 30px 0 30px;
    text-decoration: none;
    transition: 0.5s;
    overflow: hidden;
    color: #0005;
}

#SCAT a.BNN, .ui-tabs-anchor.BNN {
    font-size: 17px;
    font-weight: 600;
}

#SCAT a.active {
    background: #2fcb88;
    color: #000;
    box-shadow: 0 0 5px #2fcb88,
    0 0 25px #2fcb88,
    0 0 50px #2fcb88,
    0 0 200px #2fcb88;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}


#SCAT a:nth-child(3) {
    filter: hue-rotate(260deg);
}

#SCAT a:nth-child(2) {
    filter: hue-rotate(212deg);
}

#SCAT a span {
    position: absolute;
    display: block;
}

#SCAT a span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #2fcb88);
    animation: animate1 1s linear infinite;
}

#SCAT a span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #2fcb88);
    animation: animate2 1s linear infinite;
    animation-delay: 0.25s;
}

#SCAT a span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #2fcb88);
    animation: animate3 1s linear infinite;
    animation-delay: 0.50s;
}


#SCAT a span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #2fcb88);
    animation: animate4 1s linear infinite;
    animation-delay: 0.75s;
}

.prices {
    position: relative;
}

.marqueeD .marquee {
    padding: 5px 0;
}

.bannerCards {
    display: flex;
    justify-content: space-around;
}

.sigInfoCards, .sigAuthInfo, .sigBlogInfo {
    width: 70%;
    display: flex;
    border: none;
    margin: 50px 50px 50px 0;
}

.sigAuthInfo .card-body.bgILight {
    margin: 0 20px;
    min-height: 350px;
    background-image: url("cardBackgrounds/banner-01.svg");
    background-repeat: no-repeat;
    background-position: 10% 50%;
    background-size: 25%;
    padding: 50px;
}

.sigAuthInfo .card-body.bgIDark {
    margin: 0 20px;
    min-height: 350px;
    background-image: url("cardBackgrounds/banner-dark-01.svg");
    background-repeat: no-repeat;
    background-position: 10% 50%;
    background-size: 25%;
    padding: 50px;
}

.sigAuthInfo .card-body .textContainer {
    width: 60%;
    margin: 0 0 0 auto;
}

.sigInfoCards .card-body {
    position: relative;
}

.sigInfoCards .card-body p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.sigInfoCards .card-body.bgILight:nth-child(1) {
    background-image: url("cardBackgrounds/fast-banner.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 50%;
}

.sigInfoCards .card-body.bgIDark:nth-child(1) {
    background-image: url("cardBackgrounds/fast-banner-dark-01.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 50%;
}

.sigInfoCards .card-body.bgILight:nth-child(2) {
    background-image: url("cardBackgrounds/order-01.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 50%;
}

.sigInfoCards .card-body.bgIDark:nth-child(2) {
    background-image: url("cardBackgrounds/order-dark-01.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 50%;
}

.sigInfoCards .card-body.bgILight:nth-child(3) {
    background-image: url("cardBackgrounds/coin-banner.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 50%;
}

.sigInfoCards .card-body.bgIDark:nth-child(3) {
    background-image: url("cardBackgrounds/coin-banner-dark-01.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 50%;
}

.sigInfoCards .card-body {
    margin: 0 30px;
    min-height: 300px;
}

.sigBlogInfo .card-body {
    margin: 0 30px;
    min-height: 430px;
    position: relative;
}

.sigBlogInfo .card-body h5 {
    text-align: left;
    margin: 15px 0 0 15px;
    font-size: 25px;
    font-weight: 600;
}

.sigBlogInfo .card-body p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.sigBlogInfo .card-body:nth-child(1) {
    background-image: url("cardBackgrounds/blog-banner-3-01.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100%;
}

.sigBlogInfo .card-body:nth-child(2) {
    background-image: url("cardBackgrounds/blog-banner-2-01.svg");
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: 100%;
}

.sigBlogInfo .card-body:nth-child(3) {
    background-image: url("cardBackgrounds/blog-poster-01.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100%;
}

.textAnimation {
    animation-name: my-animation;
    -webkit-animation-name: my-animation;

    animation-duration: 2s;
    -webkit-animation-duration: 2s;

    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
}

@keyframes my-animation {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;

    }
}

.HPTableLogo {
    width: 25px;
    height: 25px;
}

.HPTableData .Name {
    font-size: 14px;
}

.MarqueeLogo {
    width: 17px;
    height: 17px;
}

.crypto-list2 {
    transform: scale(1);
}

/**************************************************************************************************************************************************************/

@media screen and (max-width: 1680px) {
    .tileRap {
        width: 80%;
    }

    .crypto-list1, .crypto-list2, .crypto-list3, .crypto-list4 {
        border: 1px solid transparent;
    }

    .center ul > :nth-child(6) {
        display: none;
    }

    .sigInfoCards, .sigAuthInfo, .sigBlogInfo {
        width: 80%;
    }
}

@media screen and (max-width: 1550px) {
    .header.darkMode {
        min-height: 780px;
        background-position: 90% -11%;
        background-size: 50% 65%;
        margin-bottom: 0;
    }

    .header.lightMode {
        min-height: 780px;
        background-position: 90% -11%;
        background-size: 50% 65%;
        margin-bottom: 0;
    }

    .priceList {
        padding: 7px 10px;
    }

}


@media screen and (max-width: 1360px) {
    .center ul > :nth-child(4) {
        display: none;
    }

    .sigInfoCards, .sigAuthInfo, .sigBlogInfo {
        width: 90%;
        margin: 20px;
    }
}

@media screen and (max-width: 1050px) {
    .center ul > :nth-child(3) {
        display: none;
    }

    .sigInfoCards, .sigAuthInfo, .sigBlogInfo {
        width: 100%;
        margin: 10px;
    }
}

@media screen and (max-width: 1000px) {
    .tileRap {
        width: 90%;
    }

    .header .header-title {
        height: 90%;
    }


    .LiMain-container ul {
        width: 135px;
        padding: 10px;
        margin: 30px 10px;
    }

    .LiMain-container ul h4 {
        margin: 0;
        text-align: center;
    }

    .crypto-list1, .crypto-list2, .crypto-list3, .crypto-list4 {
        margin: 0;
    }

    .sigInfoCards, .sigBlogInfo {
        display: block;
        width: 50%;
        margin: auto;
    }

    .sigAuthInfo {
        display: block;
        width: 70%;
        margin: auto;
    }

    .sigInfoCards .card-body, .sigBlogInfo .card-body, .sigAuthInfo .card-body {
        margin: 50px 20px;
    }

    .sigAuthInfo .card-body.bgILight,
    .sigAuthInfo .card-body.bgIDark {
        background-size: 30%;
        padding: 30px;
        background-position: 5% 50%
    }

}

@media screen and (max-width: 800px) {

    .header .header-title {
        width: 55%;
    }

    .header.lightMode {
        min-height: 250px;
        background-position: 90% 15%;
        margin-bottom: 0;
    }

    .header.darkMode {
        min-height: 250px;
        background-position: 90% 15%;
        margin-bottom: 0;
    }

    .header .header-title {
        height: 100%;
    }

    .header .JoinNow {
        width: 60px;
        margin: 10px auto;
    }

    .header .header-title h2 {
        font-size: 20px;
        margin: 0 10px 0 10px;

    }


    .HPTableLogo {
        width: 25px;
        height: 25px;
    }


    .crypto-all {
        margin-top: 0;
    }

    .prices .pc1, .prices {
        display: none;
    }

    .header .header-title {
        margin-bottom: 0;
        padding-bottom: 50px;
    }

    .slider {
        display: none;
    }

    .crypto-all {
        padding: 0;
        width: 90%;
        margin: auto;

    }


    .arrow-tabs {
        padding: 20px 0 20px 0;

    }


    .tileRap {
        width: 100%;
    }


    .Volume, .Value, .VolumeTitle, .ValueTitle {
        display: none;
    }

    .sigInfoCards, .sigBlogInfo {
        display: block;
        width: 60%;
        margin: auto;
    }

    .sigAuthInfo {
        display: block;
        width: 80%;
        margin: auto;
    }

    .sigAuthInfo .card-body.bgILight,
    .sigAuthInfo .card-body.bgIDark {
        background-image: none;
    }

    .sigAuthInfo .card-body .textContainer{
        width: 80%;
        margin: auto;
    }

    .tileRap {
        margin: 0;
    }

    .LiMain-container {
        display: flex;
        top: -70px;
        left: 0;
        width: fit-content;
    }

    .crypto-list3 {
        display: none;
    }

    .crypto-list2 {
        transform: scale(0.7);
    }

    .tileRap:before {
        top: -10px;
        left: 30px;
        right: 10px;
        bottom: 70px;
        transform: skew(2deg, 5deg);
    }

    .header .header-title {
        bottom: 5%;
    }

}

@media screen and (max-width: 600px) {
    .HPSlider {
        display: none;
    }

    #SCAT a {
        margin: 40px 30px 0 30px;
    }

    .sigInfoCards, .sigBlogInfo, .sigAuthInfo {
        display: block;
        width: 90%;
        margin: auto;
    }


    .sigAuthInfo .card-body.bgILight,
    .sigAuthInfo .card-body.bgIDark {
        background-size: 60%;
        background-position: 50% 50%;
    }

}

@media screen and (max-width: 515px) {

    .arrow-tabs {
        min-width: auto;
    }

    .arrow-tabs ul li {
        margin-left: 10px;
        margin-right: 5px;
        text-align: center;
    }


    .tileRap {
        padding: 0;
    }

    .Change, .ChangeTitle {
        display: none;
    }

}


@media screen and (max-width: 300px) {
    .tileRap:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: -1;
        transform: rotate(-5deg);
        border-radius: 30px;
    }
}

