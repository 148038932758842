
.font-face-farsi {
    font-family: "IranNastaliq";
}

.btn-close {
    box-shadow: none;
    text-decoration: none;
    border-bottom: dotted;
}

a {
    text-decoration: none;
}

.convertInputGp {
    width: 70%;
    margin: auto;
    min-height: 110px;
}

.toConvert input {
    font-family: HelveticaNeueCyrMid;
    height: 60px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 1px;
    padding-bottom: 0;
    border-radius: 0;
}

.card-body .convertForm {
    width: 50%;
}

.toConvert,
.toConvertPercent {
    margin: auto auto 0 auto;
}

.card-body.convertBGDark {
    background-image: url("cardBackgrounds/convert-dark.svg");
    background-repeat: no-repeat;
    background-size: 35%;
    background-position: 90% 20%;
}

.card-body.convertBGLight {
    background-image: url("cardBackgrounds/convert-light.svg");
    background-repeat: no-repeat;
    background-size: 35%;
    background-position: 90% 20%;
}

.toConvert input:focus, .toConvert input:active {
    outline: none;
    border-top: none;
    border-right: none;
    border-left: none;
}

.ElevatorButton {
    width: 20px;
    height: 20px;
    right: 0;
    top: 40%;
    position: absolute;
}

.authErr {
    color: #aa2934;
    text-align: center;
    font-weight: 600;
}

.toConvert input:focus {
    border-bottom: 2px dashed;
}

.balanceInfo {
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
}

.balanceInfo:hover {
    filter: brightness(0.7);
}

.convertTo input {
    height: 60px;
}

.convertIcon {
    font-size: 28px;
    font-weight: 600;
    background-color: transparent;
    border: 1px solid #00182d;
    cursor: pointer;
    text-align: center;
    background-size: 300% 100%;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding-top: 5px;
    position: absolute;
    top: 42%;
    left: 48%;
}

.bnClose {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1.5rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bnClose:hover {
    color: lightgrey;
}

.convertIcon:hover {
    background-position: -100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    color: #a4a4a4;
    border: 1px solid #fff;
}


.bnConvert {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    height: 55px;
    border: 1px solid #fff;
    text-align: center;
    background-size: 300% 100%;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    border-radius: 6px;
}

.bnConvert:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    color: #ebebeb;
    border: 1px solid #fff;
}

.bnConvert {
    background-image: linear-gradient(
            to right,
            rgba(0, 24, 45, 0.83),
            #c4c4c4,
            #00182d,
            #c4c4c4
    );
}

.convertBtn {
    width: 100px;
    background-color: transparent;
    border: none;
    margin: 0 0 auto 0;
}

.convertText {
    display: flex;
    justify-content: center;
}

.convertData {
    display: flex;
    justify-content: center;
    min-width: 150px;
}

.convertData p {
    margin: auto 0 0 0;
    font-size: 22px;

}

.convertIco {
    width: 10%;
    display: flex;
    justify-content: center;
    margin: 20px auto;
}

.convertIco svg {
    width: 50px;
    height: 50px;
}

.LogoContainerLight {
    width: 50px;
    height: 50px;
    margin: auto 10px;
    background-image: url("headers/logoCircleLight.svg");
    background-size: 100%;
    background-repeat: no-repeat;
}

.LogoContainerDark {
    width: 50px;
    height: 50px;
    margin: auto 10px;
    background-image: url("headers/logoCircleDark.svg");
    background-size: 100%;
    background-repeat: no-repeat;
}

.convertAmount {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    min-width: 200px;
}

.disabledInput {
    pointer-events: none;
    opacity: 0.4;
}


.disabledInputCompleted {
    pointer-events: none;
    opacity: 0.8;
}

.disable-content :nth-child(2) {
    pointer-events: none;
}

.CovertCard label {
    font-size: 22px;
}

.MarketSearchSecOptions:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.convertAmount.HandN {
    display: none;
}

@media screen and (max-width: 1360px) {
    .center ul > :nth-child(4) {
        display: none;
    }

    .sigInfoCards, .sigAuthInfo, .sigBlogInfo {
        width: 90%;
        margin: 20px;
    }
}

@media screen and (max-width: 1050px) {
    .card-body.convertBGDark  , .card-body.convertBGLight {
        background-size: 40%;
    }
}

@media screen and (max-width: 1000px) {
    .card-body.convertBGDark  , .card-body.convertBGLight {
        background-size: 45%;
    }
}


@media screen and (max-width: 992px) {
    .convertIcon {
        top: 15%;
        width: 50px;
        height: 50px;
        font-size: 30px;
        padding-top: 8px;
    }

    .CovertCard {
        margin: 0 20px;
    }
}

@media screen and (max-width: 700px) {
    .card-body .convertForm{
        width: 100%;
        margin-top: 350px;
    }
    .card-body.convertBGDark  , .card-body.convertBGLight {
        background-size: 50%;
        background-position: 50% 5%;
    }

}

@media screen and (max-width: 576px) {
    .bnConvert {
        padding: 0 50px;
    }

    .convertText {
        display: block;
    }

    .convertIco {
        width: 100%;
        transform: rotate(90deg);
    }

    .convertAmount.HandN {
        display: block;
        margin: 0;
    }

    .convertAmount {
        display: none;
    }
    .card-body.convertBGDark  , .card-body.convertBGLight {
        background-size: 60%;
    }

    .card-body .convertForm{
        margin-top: 250px;
    }

}

