
.spinnerLogo {
    position: absolute;
    top: 30%;
    left: 18%;
    font-size: 40px;
    font-weight: 500;
}



.loader {
    position: absolute;
    top: calc(40% - 32px);
    left: calc(46% - 32px);
    width: 200px;
    height: 200px;
    perspective: 800px;
    background-image: url("after_.gif");
    background-size: cover;
    background-repeat: no-repeat;
}

.inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 3px solid #8eb2d7;
}

.inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 3px solid #8eb2d7;
}

.inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 3px solid #8eb2d7;
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}



@media screen and (max-width: 1550px) {
    .loader {
        top: calc(40% - 52px);
        left: calc(46% - 42px);
    }
}

@media screen and (max-width: 700px) {
    .loader {
        top: calc(40% - 52px);
        left: calc(43% - 42px);
    }
}

@media screen and (max-width: 700px) {
    .loader {
        top: calc(40% - 52px);
        left: calc(43% - 42px);
    }
}

@media screen and (max-width: 600px) {
    .loader {
        top: calc(40% - 52px);
        left: calc(41% - 42px);
    }
}

@media screen and (max-width: 500px) {
    .loader {
        top: calc(40% - 52px);
        left: calc(38% - 42px);
    }
}

@media screen and (max-width: 400px) {
    .loader {
        top: calc(40% - 52px);
        left: calc(35% - 42px);
    }
}