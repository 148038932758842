.slider {
  position: absolute;
  width: 100%;
  height: 200px;
  overflow: hidden;
  bottom: 0;
  margin: 0 0 90px 0;

  & a {
    &.previousButton, &.nextButton {
      font-size: 22px;
      line-height: 0;
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: all .3s ease;
      z-index: 1;
      color: #333;
      padding: 10px;

      &:not(.disabled):hover {
        transform: translateX(-50%) scale(1.25);
        cursor: pointer;
      }
    }

    &.previousButton {
      top: 20%;
    }

    &.nextButton {
      bottom: 20px;
    }
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  &.hidden {
   display: none;
  }

  &.previous {
    top: -100%;
  }

  &.current {
    top: 0;
  }

  &.next {
    top: 100%;
  }

  &.animateIn,
  &.animateOut {
    transition: all 2s ease;
  }

  &.animateIn {
    &.previous,
    &.next {
      top: 0;
      visibility: visible;
    }
  }

  &.animateOut {
    &.previous {
      top: 100%;
    }

    &.next {
      top: -100%;
    }
  }
}

.headerFeedBack {
  width: 100%;
  position: absolute;
  filter: blur(0px);
}

.priceListContainer{
  display: flex;

}

.infoContent {
  width: 55%;
  display: flex;
}

.LogoContainerLight {
  width: 50px;
  height: 50px;
  margin: auto;
  background-image: url("headers/logoCircleLight.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
}

.LogoContainerDark {
  width: 50px;
  height: 50px;
  margin: auto;
  background-image: url("headers/logoCircleDark.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
}

.VSliderLogo{
  display: flex;
  margin: auto;
}

.LogoContainerLight svg , .LogoContainerDark svg {
  width: 30px;
  height: 35px;
  margin: auto;
}

.exact-price{
  width: 100%;
  margin: auto;
}

.chartContainer{
  width: 45%;
  max-width: 170px;
}
