/**-*********************************************************************************************************************************************/

/*footer dtyles & divs*/

.font-face-farsi {
    font-family: "IranNastaliq";
}

.footer {
    margin: 100px 0 0 0;
}

.footer-divs .dd-button {
    cursor: default;
    border: solid 2px transparent;
    background-color: transparent;
    font-weight: bold;
    font-size: 18px;
}


.footer-divs .list-link {
    display: block;
}

#list-link.BNN {
    font-weight: 600;
    font-size: 15px;
}


.icons #list-link {
    padding: 8px;
}


.email-for-info {
    font-size: larger;
    font-weight: bold;
}

.email-for-info-input input {
    border: none;
    border-bottom: solid 2px #262626;
    background-color: transparent;
}

.email-for-info-input input:focus {
    border-bottom: solid 2px #262626;
    box-shadow: none;
    background-color: transparent;
}

.email-for-info-input button {
    border: none;
    border-bottom: solid 2px #262626;
    font-weight: bold;
}

.email-for-info-input button:hover {
    text-decoration: underline;
    text-decoration-color: #003366;
    text-decoration-thickness: 3px;

}

.footer-divs-Community {
    margin: 30px auto 0 auto;
}


.reference a img {
    height: 36px;
    width: available;
}

.icons a, .icons a {
    width: 40px;
    height: 40px;
    margin: 7px;
    text-decoration: none;
}

#list-link{
    font-size: 14px;
}

.reference-s-r {
    margin: 10px 0;
}

.coin360Ico:hover .Coin360St1 {
    fill: #8CBF85;
}

.coin360Ico:hover .Coin360St2 {
    fill: #DE7976;
}

.coin360Ico:hover .Coin360St3 {
    fill: #DCDCDC;
}

.CoinMarketCap:hover .CoinMarketCapSt0 {
    fill: #3861FB;
}

.IcobenchIco:hover .IcobenchIcoSt0 {
    fill: #4954AC;
}

.CoinDesk:hover .CoinDeskSt0 {
    fill: #F8BF1E;
}

.TradingView:hover .TradingViewSt1 {
    fill: #000;
}

.CoinTelegraph:hover .CoinTelegraphSt1 {
    fill: #FABF2C;
}

.CoinTelegraph:hover .CoinTelegraphSt0 {
    fill: #000;
}

.BlockChain:hover .BlockChainSt0 {
    fill: #85B5F8;
}

.BlockChain:hover .BlockChainSt1 {
    fill: #3D89F5;
}

.BlockChain:hover .BlockChainSt2 {
    fill: #1656B9;
}

.Icodrops:hover .IcodropsSt0 {
    fill: #326DEC;
}

/*****************************************rightIcon***************************************************************************/


.SocialMediaEmail:hover .SocialMediaEmailSt0 {
    stroke: #D42E35;
}

.SocialMediaTelegram:hover .SocialMediaTelegramSt3 {
    stroke: #1C9DD9
}

.SocialMediaInstagram:hover .SocialMediaInstagramSt2 {
    stroke: #BF0791
}

.SocialMediaTwitter:hover .SocialMediaTwitterSt1 {
    stroke: #1D9BF0;
}


/*****************************************rightIcon***************************************************************************/

@media screen and (max-width: 800px) {
    .footer-divs .dd-button {
        display: inline-block;
        border: solid 1px;
        background-image: linear-gradient(to right, transparent, transparent, rgba(255, 170, 0, 0.9), #8c8c8c);
        border-radius: 4px;
        width: 100%;
        cursor: pointer;
        white-space: nowrap;
        padding: 5px 0px 5px 0px;
        background-size: 400% 100%;
        moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;

    }

    .footer-divs .dd-button:focus {
        background-position: 100% 0;
        moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
    }

}