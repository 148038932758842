*, *:after, *:before {
    box-sizing: border-box;
}

.font-face-farsi {
    font-family: "IranNastaliq";
}

.ContactUsContainer {
    padding: 50px 25px;

}

.AboutUsAddress, .AboutUsPhoneNumber {
    display: flex;
    justify-content: center;
}

.AboutUsWorkingHours , .AboutUsAddress ,
.AboutUsPhoneNumber{
    text-align: center;
    margin: 30px 0;
}

.AboutUsAddress p, .AboutUsPhoneNumber p {
    font-size: 20px;
    margin: 0 5px;
}

.AboutUsAddress p, .AboutUsPhoneNumber p {
    font-size: 20px;
    margin: 0 5px;
}


.AboutUsWorkingHours p {
    margin: 0 1px;
    min-width: 130px;
}