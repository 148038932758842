.ACCnavbar {
    width: 100%;
    background-color: transparent;
}


.ACCnavbar .ACC-nav-btns {
    text-align: center;
    padding: 7px 16px;
    text-decoration: none;
    font-size: 17px;
    width: 25%;
    border-radius: 30px;
}

.ACCnavbar .ACC-nav-btns p {
    margin: 0;
}

.ACCnavbar .ACC-nav-btns:hover {
    cursor: pointer;
}

.ACCnavbar .ACC-nav-btns.active {
    background-color: #04AA6D;
    color: white;
}

.AccSettingIco , .Idvico  , .Mcico , .SettingPageIco{
    max-width: 35px;
    margin: 2px auto;
}

@media screen and (max-width: 700px) {
    .ACCnavbar .ACC-nav-btns {
        width: 100%;
        justify-content: center;
    }
}